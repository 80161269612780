import {
  ILocationSearchRequest,
  IEncodedSearchPathParams,
  IEncodedSearchQueryParams,
} from '../location.types';

import {
  decodeQuery,
  decodeQueryFromPath,
  decodeDocumentType,
  decodePageNumber,
  decodeProductPrice,
  decodeProductCollections,
} from './serializers';

export function decodeSearchRequestFromPathParams(
  params: IEncodedSearchPathParams,
): ILocationSearchRequest {
  const query = decodeQueryFromPath(params.q);
  const documentType = decodeDocumentType(params.qc);
  const page = decodePageNumber(params.page);

  return {
    ...(query !== undefined && { query }),
    ...(documentType !== undefined && { documentType }),
    ...(page !== undefined && { page }),
  };
}

export function decodeSearchRequestFromQueryParams(
  params: IEncodedSearchQueryParams,
): ILocationSearchRequest {
  const query = decodeQuery(params.q);
  const documentType = decodeDocumentType(params.type);
  const page = decodePageNumber(params.page);
  const { minPrice, maxPrice } = decodeProductPrice(params.price);
  const collections = decodeProductCollections(params.collections);

  return {
    ...(query !== undefined && { query }),
    ...(documentType !== undefined && { documentType }),
    ...(page !== undefined && { page }),
    ...(minPrice !== undefined && { minPrice }),
    ...(maxPrice !== undefined && { maxPrice }),
    ...(collections !== undefined && { collections }),
  };
}
