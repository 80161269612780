import { debounce, isMatch } from 'lodash';
import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import {
  ClientSearchSDK,
  ISearchProductDocument,
  ISearchRequest,
  ISearchResponse,
  ISearchSDKDemoContentOptions,
  SearchDocumentType,
  ISearchSDKMockOptions,
} from '@wix/client-search-sdk';

import initSchemaLogger, {
  documentClickParams,
  loadingFinishedParams,
  Logger,
} from '@wix/bi-logger-wix-search-widget';

import { IWidgetControllerConfig } from '../../../../../lib/platform.types';
import {
  ISearchLocation,
  ILocationSearchRequest,
} from '../../../../../lib/location';
import { getTotalPages } from '../pagination';
import {
  addProductToCart,
  convertProductFacetsFilterToRequestParams,
  convertProductFacetsRequestParamsToFilter,
  extractProductFacetsFromSearchResponse,
  IProductFacetsFilter,
  IProductFacetsState,
} from '../products';
import {
  DocumentTypeChangeSource,
  SearchRequestStatus,
} from '../../types/types';
import {
  createBICorrelationId,
  BICorrelationIdKey,
  BICorrelationIdStore,
  getBITotals,
  getAbsoluteDocumentIndex,
  getBIAvailableFacets,
  getBISelectedFacets,
} from '../../../../SearchBox/platform/bi';

import { Settings } from '../extractSettings';
import {
  DocumentClickOrigin,
  ISearchResultsControllerProps,
  ISearchSortConfig,
  ISearchSample,
  ISeoItemData,
  SearchResultsControllerStoreState,
  VisibleCategories,
} from './SearchResultsControllerStore.types';
import {
  getSortOptions,
  getOrdering,
  getDefaultSortOption,
  isSortSupported,
  SortOptionId,
} from '../sort';
import { search } from '../search';
import { createSearchRequestBI } from '../../../../SearchBox/platform/bi/createSearchRequestBI';
import { equalSearchRequests } from './equalSearchRequests';
import { getVisibleCategories } from './getVisibleCategories';
import { withDocumentType } from './withDocumentType';
import { Spec } from '../../../../../lib/specs';

export class SearchResultsControllerStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly wixCodeApi: IWixAPI;
  private readonly setComponentProps: (
    props: SearchResultsControllerStoreState,
  ) => void;
  private readonly biLogger: Logger;
  private readonly biCorrelationIdStore: BICorrelationIdStore;
  private readonly reportError: (error: Error) => void;
  private readonly searchSDK: ClientSearchSDK;
  private readonly searchLocation: ISearchLocation;
  private readonly buildSearchAllUrl: IWidgetControllerConfig['buildSearchAllUrl'];

  private demoContentOptions!: ISearchSDKDemoContentOptions;
  private documentTypes: SearchDocumentType[];
  private state: SearchResultsControllerStoreState;

  constructor(
    {
      buildSearchAllUrl,
      platformAPIs,
      wixCodeApi,
      searchSDK,
      searchLocation,
      setProps,
      flowAPI,
    }: IWidgetControllerConfig,
    settings: Settings,
  ) {
    this.flowAPI = flowAPI;
    this.setComponentProps = setProps;
    this.wixCodeApi = wixCodeApi;
    this.reportError = flowAPI.reportError;
    this.searchSDK = searchSDK;
    this.searchLocation = searchLocation;
    this.documentTypes = [];
    this.buildSearchAllUrl = buildSearchAllUrl;

    const { language, isViewer } = flowAPI.environment;
    const locationParams = this.getSearchRequestParamsFromLocation();
    const locale = wixCodeApi.site.regionalSettings || language;

    this.state = {
      ...this.getEmptyResponseStateProps(),
      apiErrorDetails: undefined,
      reportError: this.reportError,
      locale,
      searchResultsAbsoluteUrl: '',
      settings,
      searchRequest: {} as ISearchRequest,
      searchRequestStatus: SearchRequestStatus.Initial,
      documentTypes: [],
      onDocumentTypeChange: this.handleDocumentTypeChange,
      onQuerySubmit: this.handleQuerySubmit,
      onPageChange: this.handlePageChange,
      onDocumentClick: this.handleDocumentClick,
      onProductAddToCart: this.handleProductAddToCart,
      onResetProductFacetsFilter: this.handleResetProductFacetsFilter,
      onProductFacetsFilterChange: this.handleProductFacetsFilterChange,
      // TODO: cleanup when resolved https://github.com/wix-private/native-components-infra/pull/28
      viewMode: wixCodeApi.window.viewMode,
      isDemoContent: !isViewer,
      sortConfig: {
        onSortChange: this.onSortChange,
        selectedSortOption: 0,
        showSort: false,
        sortOptions: [],
      },
      productFacets: {} as IProductFacetsState,
    };

    this.state.searchRequest = this.getSearchRequestFromLocationParams(
      locationParams,
      settings.itemsPerPage,
    );

    this.state.productFacets = {
      ...this.getUpdatedProductFacetsState(
        this.state.searchRequest,
        this.state.searchResponse,
      ),
      filter: {
        minPrice: locationParams.minPrice,
        maxPrice: locationParams.maxPrice,
        collections: locationParams.collections,
      },
    };

    if (this.state.isDemoContent) {
      this.setDemoContentOptions({
        shouldMockDocumentTypes: false,
        shouldHaveSearchResults: true,
      });
    }
    this.biLogger = initSchemaLogger(platformAPIs.biLoggerFactory?.())();
    this.biCorrelationIdStore = new BICorrelationIdStore(
      platformAPIs,
      this.reportError,
    );

    wixCodeApi.location.onChange(() => {
      const stateSearchRequest = this.state.searchRequest;
      const locationSearchRequest = this.getSearchRequestFromLocationParams(
        this.getSearchRequestParamsFromLocation(),
        stateSearchRequest.paging.pageSize,
      );
      if (equalSearchRequests(locationSearchRequest, stateSearchRequest)) {
        return;
      }
      this.changeSearchRequest(locationSearchRequest);
    });
  }

  private shouldShowProductFacets(documentType?: SearchDocumentType): boolean {
    const { settings } = this.state;
    const { experiments, environment } = this.flowAPI;

    return (
      settings.isProductsFacetsEnabled &&
      documentType === SearchDocumentType.Products &&
      experiments.enabled(Spec.Facets) &&
      !environment.isEditorX
    );
  }

  private getUpdatedProductFacetsState(
    searchRequest: ISearchRequest,
    searchResponse: ISearchResponse,
  ): IProductFacetsState {
    return {
      ...this.state.productFacets,
      enabled: this.shouldShowProductFacets(searchRequest.documentType),
      filter: convertProductFacetsRequestParamsToFilter(searchRequest),
      ...extractProductFacetsFromSearchResponse(searchResponse),
    };
  }

  private getSearchRequestParamsFromLocation(): ILocationSearchRequest {
    return this.searchLocation.decodeParams();
  }

  private getSearchRequestFromLocationParams(
    locationParams: ILocationSearchRequest,
    pageSize: number,
  ): ISearchRequest {
    return this.searchLocation.toSDKSearchRequest(locationParams, pageSize);
  }

  private setDemoContentOptions(partialOptions: ISearchSDKMockOptions) {
    if (
      this.demoContentOptions &&
      isMatch(this.demoContentOptions, partialOptions)
    ) {
      return;
    }

    this.demoContentOptions = {
      ...this.demoContentOptions,
      ...partialOptions,
    };
    this.searchSDK.useDemoContent(this.demoContentOptions);
  }

  private setState(partialState: Partial<SearchResultsControllerStoreState>) {
    this.state = {
      ...this.state,
      ...partialState,
    };

    this.setComponentProps(this.state);
  }

  private getSearchSubmitCorrelationId(): string | undefined {
    const key = BICorrelationIdKey.SearchSubmit;
    return this.biCorrelationIdStore.has(key)
      ? this.biCorrelationIdStore.get(key)
      : undefined;
  }

  private async search(
    searchRequest: ISearchRequest,
    visibleCategories: VisibleCategories,
    previousSearchRequestStatus: SearchRequestStatus,
  ) {
    searchRequest = this.withOrdering(
      withDocumentType(searchRequest, visibleCategories),
    );

    const facetsEnabled = this.shouldShowProductFacets(
      searchRequest.documentType,
    );

    if (facetsEnabled) {
      searchRequest = this.withFacets(searchRequest);
    }

    const { environment } = this.flowAPI;

    try {
      const searchResult = await search({
        searchRequest,
        searchSDK: this.searchSDK,
        previousQuery: this.state.previousQuery,
        environment,
        facetsEnabled,
        previousTotals: this.state.searchResponseTotals,
        previousSearchRequestStatus,
        previousSearchRequest: this.state.searchRequest,
        previousSearchResponse: this.state.searchResponse,
        searchResultsAbsoluteUrl: this.state.searchResultsAbsoluteUrl,
        searchLocation: this.searchLocation,
        visibleCategories,
        bi: createSearchRequestBI({
          biLogger: this.biLogger,
          isDemoContent: this.state.isDemoContent,
          isEnabled: !environment.isSSR,
          searchRequest,
          correlationId: this.getSearchSubmitCorrelationId(),
        }),
      });

      if ('isError' in searchResult) {
        this.reportError(new Error(JSON.stringify(searchResult.errorDetails)));
        return {
          ...this.getFailedQueryStateProps(),
          apiErrorDetails: searchResult.errorDetails,
        };
      }

      const {
        searchResponse,
        searchResponseTotals,
        searchSamples,
      } = searchResult;

      // NOTE: wixCodeApi.site.currency can return undefined for some websites - we are
      // extracting currency from products schema instead.
      const currency =
        this.extractCurrencyFromSamples(searchSamples) || this.state.currency;

      const result = {
        apiErrorDetails: undefined,
        currency,
        searchRequest,
        searchResponse,
        searchResponseTotals,
        searchSamples,
        searchRequestStatus: SearchRequestStatus.Loaded,
        previousQuery: searchRequest.query,
        productFacets: this.getUpdatedProductFacetsState(
          searchRequest,
          searchResponse,
        ),
        sortConfig: this.getUpdatedSortConfig(
          searchRequest.documentType,
          searchResponse.totalResults,
        ),
      };

      try {
        await this.renderSeo(result);
      } catch (error: any) {
        this.reportError(error);
      }

      return result;
    } catch (error: any) {
      this.reportError(error);

      return {
        ...this.getFailedQueryStateProps(),
        error: JSON.stringify(error),
      };
    }
  }

  private async changeSearchRequest(
    searchRequestRaw: ISearchRequest,
  ): Promise<void> {
    const previousSearchRequestStatus = this.state.searchRequestStatus;

    this.setState({
      searchRequestStatus: SearchRequestStatus.Loading,
    });

    const visibleCategories = getVisibleCategories(
      this.state.settings,
      this.documentTypes,
    );

    try {
      const partialState = await this.search(
        searchRequestRaw,
        visibleCategories,
        previousSearchRequestStatus,
      );
      this.setState(partialState);
    } catch (error: any) {
      this.setState({
        ...this.getFailedQueryStateProps(),
        error: JSON.stringify(error),
      });
      this.reportError(error);
    }
  }

  private readonly changeSearchRequestLazy: (
    request: ISearchRequest,
  ) => void = debounce(this.changeSearchRequest, 500);

  updateSettings(settings: Settings) {
    const prevSettings = this.state.settings;

    this.setState({
      settings,
    });

    if (prevSettings.itemsPerPage !== settings.itemsPerPage) {
      this.changeSearchRequestLazy({
        ...this.state.searchRequest,
        paging: {
          page: 1,
          pageSize: settings.itemsPerPage,
        },
      });
    }

    // https://github.com/wix-private/site-search/issues/153
    // this.settingsEventHandler.updateData(appPublicData);
  }

  getSearchSDK(): ClientSearchSDK {
    return this.searchSDK;
  }

  private applySearchRequest(
    searchRequest: ISearchRequest,
    disableScrollTop?: boolean,
  ) {
    if (
      this.state.isDemoContent ||
      equalSearchRequests(this.state.searchRequest, searchRequest)
    ) {
      this.changeSearchRequest(searchRequest);
      return;
    }

    this.searchLocation.navigateToSearchResults(
      this.searchLocation.toLocationSearchRequest(searchRequest),
      disableScrollTop,
    );
  }

  changeDocumentType = (
    documentType: SearchDocumentType,
    disableScrollTop?: boolean,
  ) => {
    const { searchRequest } = this.state;

    this.setState({
      sortConfig: {
        ...this.state.sortConfig,
        selectedSortOption: getDefaultSortOption(),
      },
    });

    this.applySearchRequest(
      {
        ...searchRequest,
        documentType,
        filter: undefined,
        paging: {
          ...searchRequest.paging,
          page: 1,
        },
      },
      disableScrollTop,
    );
  };

  changeQuery = (query: string, disableScrollTop?: boolean) => {
    const { searchRequest } = this.state;

    this.state.productFacets.filter = {};

    this.applySearchRequest(
      {
        ...searchRequest,
        query,
        filter: undefined,
        paging: {
          ...searchRequest.paging,
          page: 1,
        },
      },
      disableScrollTop,
    );
  };

  changePage = (page: number) => {
    const searchRequest = this.state.searchRequest;

    this.applySearchRequest({
      ...searchRequest,
      paging: {
        ...searchRequest.paging,
        page,
      },
    });
  };

  private extractCurrencyFromSamples(
    searchSamples: ISearchSample[],
  ): string | undefined {
    const productSamples = (searchSamples.find(
      ({ documentType }) => documentType === SearchDocumentType.Products,
    )?.documents ?? []) as ISearchProductDocument[];

    return productSamples.length > 0 ? productSamples[0].currency : undefined;
  }

  updateDemoMode(data: {
    shouldHaveSearchResults: boolean;
    shouldSetNonAllDocumentType: boolean;
  }) {
    const { shouldHaveSearchResults, shouldSetNonAllDocumentType } = data;

    let searchRequest = this.state.searchRequest;
    let isSearchRequestChanged = false;
    let isDemoContentOptionsChanged = false;

    if (
      shouldHaveSearchResults !==
      this.demoContentOptions.shouldHaveSearchResults
    ) {
      this.setDemoContentOptions({
        shouldHaveSearchResults,
      });

      isDemoContentOptionsChanged = true;
    }

    if (shouldSetNonAllDocumentType) {
      const documentTypeSource = this.state.documentTypes.find(
        ({ documentType }) => documentType !== SearchDocumentType.All,
      );

      if (
        documentTypeSource &&
        documentTypeSource.documentType !== searchRequest.documentType
      ) {
        searchRequest = {
          ...searchRequest,
          documentType: documentTypeSource.documentType,
        };

        isSearchRequestChanged = true;
      }
    }

    if (isDemoContentOptionsChanged || isSearchRequestChanged) {
      this.applySearchRequest(searchRequest);
    }
  }

  private readonly handleDocumentTypeChange = (
    documentType: SearchDocumentType,
    documentTypeChangeSource: DocumentTypeChangeSource,
  ): void => {
    this.biLogger.documentTypeChange({
      isDemo: this.state.isDemoContent,
      target: this.state.searchRequest.query,
      correlationId: this.getSearchSubmitCorrelationId(),
      tabName: documentType,
      source:
        documentTypeChangeSource === DocumentTypeChangeSource.ViewAllButton
          ? 'samples'
          : 'tabs',
    });

    this.changeDocumentType(
      documentType,
      documentTypeChangeSource === DocumentTypeChangeSource.Tab,
    );
  };

  private readonly handleQuerySubmit = (query: string): void => {
    const correlationId = createBICorrelationId();

    this.biCorrelationIdStore.set(
      BICorrelationIdKey.SearchSubmit,
      correlationId,
    );

    // TODO: add clickOrigin
    this.biLogger.searchSubmit({
      isDemo: this.state.isDemoContent,
      target: query,
      correlationId,
    });

    this.changeQuery(query, true);
  };

  private readonly handlePageChange = (page: number) => {
    // const correlationId = this.biCorrelationIdStore.get(
    //   BICorrelationIdKey.SearchSubmit,
    // );

    // TODO: uncomment, when need `pageChange` event.
    // this.biLogger.pageChange({
    //   isDemo: this.state.isDemoContent,
    //   target: page,
    //   correlationId,
    // });

    this.changePage(page);
  };

  private readonly handleDocumentClick: ISearchResultsControllerProps['onDocumentClick'] = (
    searchDocument,
    index: number,
    clickOrigin,
  ): void => {
    const { searchRequest } = this.state;

    this.logBiDocumentClick({
      documentId: searchDocument.id,
      documentType: searchDocument.documentType,
      pageUrl: searchDocument.url,
      resultClicked: searchDocument.title,
      searchIndex: getAbsoluteDocumentIndex(searchRequest.paging, index),
      clickOrigin,
    });
    this.wixCodeApi.location.to?.(searchDocument.relativeUrl);
  };

  private readonly onSortChange = (selectedSortOption: SortOptionId) => {
    const { settings } = this.state;
    if (selectedSortOption !== this.state.sortConfig.selectedSortOption) {
      this.setState({
        sortConfig: {
          ...this.state.sortConfig,
          selectedSortOption,
        },
      });
      this.changeSearchRequest({
        ...this.state.searchRequest,
        paging: {
          page: 1,
          pageSize: settings.itemsPerPage,
        },
      });
    }
  };

  private readonly handleProductAddToCart = async (
    product: ISearchProductDocument,
  ): Promise<void> => {
    this.logBiDocumentClick({
      clickOrigin: 'add_to_cart',
      documentId: product.id,
      documentType: product.documentType,
    });
    await addProductToCart(product, this.wixCodeApi);
  };

  private readonly handleResetProductFacetsFilter = () => {
    this.logBiClickResetFacets();

    this.handleProductFacetsFilterChange({
      collections: undefined,
      maxPrice: undefined,
      minPrice: undefined,
    });
  };

  private readonly handleProductFacetsFilterChange = (
    filter: IProductFacetsFilter,
  ): void => {
    const { searchRequest, productFacets } = this.state;

    const mergedFilter = {
      ...productFacets.filter,
      ...filter,
    };

    this.setState({
      productFacets: {
        ...productFacets,
        filter: mergedFilter,
      },
    });

    this.applySearchRequest(
      {
        ...searchRequest,
        ...convertProductFacetsFilterToRequestParams(mergedFilter),
        paging: {
          ...searchRequest.paging,
          page: 1,
        },
      },
      true,
    );
  };

  shouldShowSort(
    documentType: SearchDocumentType | undefined,
    resultsCount: number,
  ): boolean {
    return !!documentType && resultsCount > 0 && isSortSupported(documentType);
  }

  getUpdatedSortConfig(
    documentType: SearchDocumentType | undefined,
    resultsCount: number,
  ): ISearchSortConfig {
    return {
      ...this.state.sortConfig,
      showSort: this.shouldShowSort(documentType, resultsCount),
      sortOptions: getSortOptions(documentType),
    };
  }

  withOrdering(searchRequest: ISearchRequest): ISearchRequest {
    return {
      ...searchRequest,
      ordering: getOrdering(this.state.sortConfig.selectedSortOption),
    };
  }

  withFacets(searchRequest: ISearchRequest): ISearchRequest {
    return {
      ...searchRequest,
      ...convertProductFacetsFilterToRequestParams(
        this.state.productFacets.filter,
      ),
    };
  }

  getEmptyResponseStateProps() {
    return {
      searchResponseTotals: {},
      searchSamples: [],
      searchResponse: {
        documents: [],
        facets: [],
        totalResults: 0,
      },
    };
  }

  getFailedQueryStateProps(): Partial<SearchResultsControllerStoreState> {
    return {
      ...this.getEmptyResponseStateProps(),
      apiErrorDetails: undefined,
      error: undefined,
      previousQuery: undefined,
      searchRequestStatus: SearchRequestStatus.Failed,
    };
  }

  logBiClickResetFacets() {
    // 99:307 SearchResults - Click Reset Facets
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/307?artifactId=com.wixpress.wix-search-widget
    this.biLogger.searchResultsClickResetFacets({
      availableFacets: getBIAvailableFacets(
        this.state.searchRequest,
        this.state.searchResponse,
      ),
      clickOrigin: 'empty_result_page',
      correlationId: this.getSearchSubmitCorrelationId(),
      selectedFacets: getBISelectedFacets(this.state.searchRequest),
      target: this.state.searchRequest.query,
      isDemo: this.state.isDemoContent,
    });
  }

  logBiDocumentClick(
    p: Pick<
      documentClickParams,
      | 'documentId'
      | 'documentType'
      | 'pageUrl'
      | 'searchIndex'
      | 'resultClicked'
    > & { clickOrigin: DocumentClickOrigin },
  ): void {
    const { isDemoContent, searchResponseTotals } = this.state;
    // 99:305 searchResults.results.click
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/305?artifactId=com.wixpress.wix-search-widget
    this.biLogger.documentClick({
      correlationId: this.getSearchSubmitCorrelationId(),
      isDemo: isDemoContent,
      resultsArray: getBITotals(searchResponseTotals),
      target: this.state.searchRequest.query,
      ...p,
    });
  }

  logBiLoadingStarted(p: loadingFinishedParams = {}): void {
    if (this.flowAPI.environment.isSSR) {
      return;
    }
    // 99:301 searchResults.loading.started
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/301?artifactId=com.wixpress.wix-search-widget
    this.biLogger.loadingStarted(p);
  }

  logBiLoadingFinished(p: loadingFinishedParams): void {
    if (this.flowAPI.environment.isSSR) {
      return;
    }
    // 99:302 searchResults.loading.finished
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/302?artifactId=com.wixpress.wix-search-widget
    this.biLogger.loadingFinished(p);
  }

  redirectOnInvalidPageRequest() {
    const { searchRequest, searchResponse } = this.state;
    const { pageSize, page } = searchRequest.paging;
    const totalPages = getTotalPages(pageSize, searchResponse.totalResults);

    if (page > totalPages) {
      this.applySearchRequest({
        ...searchRequest,
        paging: {
          ...searchRequest.paging,
          page: 1,
        },
      });
    }
  }

  async redirectFromPathParams(): Promise<void> {
    const { experiments } = this.flowAPI;
    if (!experiments.enabled(Spec.UrlQueryParams)) {
      return;
    }

    const pathParams = this.searchLocation.decodeParamsFromPath();
    if (!Object.keys(pathParams).length) {
      return;
    }

    await this.searchLocation.navigateToSearchResults({
      ...this.getSearchRequestParamsFromLocation(),
      ...pathParams,
    });
  }

  async renderSeo({
    searchRequest,
    searchSamples,
    searchResponse,
  }: Pick<
    SearchResultsControllerStoreState,
    'searchRequest' | 'searchSamples' | 'searchResponse'
  >) {
    if (!this.flowAPI.experiments.enabled(Spec.RenderSeoTags)) {
      return;
    }

    const resultType = searchRequest.documentType;
    const searchTerm = searchRequest.query;
    const documents =
      resultType === SearchDocumentType.All
        ? searchSamples
        : searchResponse.documents;

    const itemData: ISeoItemData = {
      allResultsUrl: await this.buildSearchAllUrl(searchTerm),
      documents,
      pageUrl: this.wixCodeApi.location.url,
      resultsTotal: searchResponse.totalResults,
      resultType,
      searchTerm,
    };

    this.wixCodeApi.seo.renderSEOTags({
      itemType: ITEM_TYPES.SEARCH_PAGE,
      itemData,
    });
  }

  async setInitialState(): Promise<void> {
    await this.redirectFromPathParams();

    if (!this.biCorrelationIdStore.has(BICorrelationIdKey.SearchSubmit)) {
      // NOTE: there are no correlationId from previous submit, so create new one
      this.biCorrelationIdStore.set(
        BICorrelationIdKey.SearchSubmit,
        createBICorrelationId(),
      );
    }

    const startTime = Date.now();

    this.logBiLoadingStarted();

    try {
      const composedPromise = Promise.all([
        this.searchLocation.getSearchResultsAbsoluteUrl(),
        this.searchSDK.getDocumentTypes(),
      ]);

      const [searchResultsAbsoluteUrl, documentTypes] = await composedPromise;

      this.documentTypes = documentTypes.map((t) => t.documentType);

      if (this.state.isDemoContent) {
        this.setDemoContentOptions({
          documentTypesForSearchResults: this.documentTypes,
        });
      }

      const visibleCategories = getVisibleCategories(
        this.state.settings,
        this.documentTypes,
      );

      const partialState = await this.search(
        this.state.searchRequest,
        visibleCategories,
        this.state.searchRequestStatus,
      );

      this.setState({
        searchResultsAbsoluteUrl,
        documentTypes,
        ...partialState,
      });

      this.logBiLoadingFinished({
        loadingDuration: Date.now() - startTime,
        success: true,
      });

      if (this.state.searchRequestStatus !== SearchRequestStatus.Failed) {
        this.redirectOnInvalidPageRequest();
      }
    } catch (error: any) {
      this.setState({
        ...this.getFailedQueryStateProps(),
        error: JSON.stringify(error),
      });

      this.logBiLoadingFinished({
        error: error.toString(),
        loadingDuration: Date.now() - startTime,
        success: false,
      });

      this.reportError(error);
    }
  }
}
